.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* header css */
.theme_bg_container{
  background-color: var(--theme-color);             
}
.theme_light_container{
  background-color: var(--theme-light-color);  
}
.navBars > span{
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  width: fit-content;
}
.webLogo{
  height: 4rem;
  width: 100%;
}
.headerSection{
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0 0 #0000,0 0 #0000,0 0 #0000,0 0 #0000,0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

/* footer css */
.footerSubTitle {
  font-size: 0.875rem;
  color: #fff;
}
.footerLogoText{
  font-size: 1.875rem;
  /* line-height: 2.25rem; */
  color: #fff;
}
.copyRightSection{
  color: #fff;
}
.linkSection div{
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  width: fit-content;
}
.linkSection div a{
  color: #fff!important;
  text-decoration: none!important;
}


/* comman css */
.pageTitleText{
  font-size: 3rem;
  font-weight: 600;
}
.webNamesubText{
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  color: #4b5563;
}
.webComanTitle{
  font-weight: 600;
  font-size: 1.875rem;
}
.clearOption{
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  width: fit-content;
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin-left: auto;
}
.boxSection{
  --tw-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  background-color: #fff;
  border-radius: 6px;
  padding: 1.5rem;
}
.commanButton:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: var(--tw-transform);
}
.sectionHeading{
  font-size: 1.5rem !important;
  color: #fff;
  border-bottom: none!important;
}
.iconSection{
  width: 22px;
  margin-right: 5px;
}
.innericonSection{
  width: 22px;
  margin-right: 10px;
}
.shareContainer{
  margin-left: auto;
}
.customeSetting{
  border: 1px solid #c3c7cb;
  border-radius: 6px;
  padding: 5px 11px;
  width: 100%;
}

.customeSetting:focus-visible{
  border: 1px solid #c3c7cb;
  outline: none;
}
.socialIconSection{
  padding-left: 50px!important;
}

/* button css */
.themeButton{
  background-color: var(--theme-color)!important;
  color: #fff!important;
  border-radius: 4px!important;
}
.sendOption{
  background-color: #25d366!important;
  color: #fff!important;
  border-radius: 6px;
  text-align: center;
  width: 123px!important;
  /* padding: auto; */
}

/* Popover styling */

a {
  text-decoration: none;
}

.popover__title {
  font-size: 21px;
  line-height: 36px;
  text-decoration: none;
  text-align: center;
  margin: 0;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0px;
  transform: translate(0, 10px);
  background-color: #e5e7eb;
  padding: 1rem 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e5e7eb transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}
.infoPageOption a{
 text-decoration: none !important;
 color: #fff!important;
}

/* check box css */
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: var(--theme-color) !important;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type="checkbox"] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    /* &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    } */
  }
  /* input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    &:checked {
      --s: 0.5;
    }
  } */
}

/* About Us page css */
.responsiveHeight{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  --tw-bg-opacity: 1;
  /* display: flex;
  flex-direction: column;
  min-height: calc(100vh - 323px); */
}

/* notification toster */
#toaster {
  position: fixed;
  top: 20px;
  right: 2%;
  /* transform: translateX(98%); */
  z-index: 999;
  display: none;
  width: 250px;
}

.toasts {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  /* opacity: 0; */
  transition: opacity 0.3s ease-in-out;
}

.show {
  display: block !important;
}


/* whatsapp info page */
.fPwoYX {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.infoImage {
  border-radius: 0.375rem;
}



/* Media Query for low resolution  Tablets, Ipads */
  @media (max-width: 1025px) {
      .navBars{
        align-items: center;
      }
      .footerSubTitles{
        font-size: 0.875rem;
      }
      .footerLogoText{
        font-size: 1.5rem;
        /* line-height: 2rem; */
      }
      .sectionHeading{
        font-size: 1.25rem;
        color: #fff;
        border-bottom: none!important;
      }
      .pageTitleText{
        font-weight: 600;
        font-size: 2.25rem;
      }
      .infoImage {
        border-radius: 0.375rem;
        width: 100%;
        height: auto;
      }
      .webNamesubText{
        font-size: 1rem;
        font-weight: 700;
        line-height: normal;
      }
      .socialIconSection{
        padding: 0!important;
        padding-top: 15px !important;
      }
      .linkSection div:first-child{
        padding-top: 0px;
      }
      .linkSection div{
        padding: 4px 0px;
      }
  }

  @media (max-width: 780px) {
    .webLogo{
      height: 3rem;
      width: 12rem;
    }
  }
    
  /* Media Query for Laptops and Desktops */
  /* @media (min-width: 1025px) and (max-width: 1280px){
      body {
          background-color: green;
      }
  } */
    
  /* Media Query for Large screens */
  @media (min-width: 1281px) {
      body {
          background-color: white;
      }
      .navBars{
        align-items: left;
      }
  }