/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

:root{
  --theme-color: #27b53d;
  --theme-light-color: #55d06123;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  height: 100%;
  margin: auto;
  flex-grow: 1;
}

.pl-0{
  padding-left: 0!important;
}
.pl-1 {
  padding-left: 0.25rem!important;
}
.pl-2 {
  padding-left: 0.5rem!important;
}
.pl-3 {
  padding-left: 1rem!important;
}
.pl-4 {
  padding-left: 1.5rem!important;
}
.pl-5 {
  padding-left: 3rem!important;
}

.pr-0{
  padding-right: 0!important;
}
.pr-1 {
  padding-right: 0.25rem!important;
}
.pr-2 {
  padding-right: 0.5rem!important;
}
.pr-3 {
  padding-right: 1rem!important;
}
.pr-4 {
  padding-right: 1.5rem!important;
}
.pr-5 {
  padding-right: 3rem!important;
}

.cursor-pointer{
  cursor: pointer;
}
